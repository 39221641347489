//
// Component: Placeholder
//
// ========================================================================


// Variables
// ========================================================================

$placeholder-background:                         transparent !default;

//
// New
//

$placeholder-border-width:                       $global-border-width !default;
$placeholder-border:                             $global-border !default;


// Component
// ========================================================================




// Miscellaneous
// ========================================================================

// @mixin hook-placeholder-misc(){}
