//
// Component: Heading
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-heading-small(){}

// @mixin hook-heading-medium(){}

// @mixin hook-heading-large(){}

// @mixin hook-heading-xlarge(){}

// @mixin hook-heading-2xlarge(){}


// Divider
// ========================================================================

// @mixin hook-heading-divider(){}


// Bullet
// ========================================================================

// @mixin hook-heading-bullet(){}


// Line
// ========================================================================

// @mixin hook-heading-line(){}



// Miscellaneous
// ========================================================================

// @mixin hook-heading-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-heading-small(){}

// @mixin hook-inverse-heading-medium(){}

// @mixin hook-inverse-heading-large(){}

// @mixin hook-inverse-heading-xlarge(){}

// @mixin hook-inverse-heading-2xlarge(){}

// @mixin hook-inverse-heading-divider(){}

// @mixin hook-inverse-heading-bullet(){}

// @mixin hook-inverse-heading-line(){}
