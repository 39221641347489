//
// Component: Alert
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$alert-close-opacity:                            0.4 !default;
$alert-close-hover-opacity:                      0.8 !default;


// Component
// ========================================================================

// @mixin hook-alert(){}


// Close
// ========================================================================






// Style modifiers
// ========================================================================

// @mixin hook-alert-primary(){}

// @mixin hook-alert-success(){}

// @mixin hook-alert-warning(){}

// @mixin hook-alert-danger(){}


// Miscellaneous
// ========================================================================

