//
// Component: Marker
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================



// @mixin hook-marker-hover(){}


// Miscellaneous
// ========================================================================

// @mixin hook-marker-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-marker(){}
// @mixin hook-inverse-marker-hover(){}
