// 1. Your custom variables and variable overwrites.
$global-gutter:20px;
$global-small-gutter:10px;
$global-medium-gutter:20px;
$global-large-gutter:40px;

$global-margin:$global-gutter;
$global-small-margin:$global-small-gutter;
$global-medium-margin:$global-medium-gutter;
$global-large-margin:$global-large-gutter;

$container-padding-horizontal:$global-gutter;
$container-padding-horizontal-s:$global-gutter;
$container-padding-horizontal-m:$global-gutter;

$padding-padding:$global-gutter;
$padding-padding-l:$global-gutter;
$padding-small-padding:$global-small-gutter;
$padding-large-padding:$global-large-gutter;
$padding-large-padding-l:$global-large-gutter;

$margin-large-margin:$global-large-margin !default;
$margin-xlarge-margin:$global-large-margin * 2 !default;

$base-heading-margin-top:0;

$global-font-family:'Montserrat', sans-serif;
$base-heading-font-family:'Roboto Condensed', sans-serif;
$base-body-font-weight:300;
$base-heading-font-weight:400;
$base-strong-font-weight:600;

$global-xxlarge-font-size:1.25rem;
$base-heading-margin-top:0;
$base-h1-font-size:1.25rem;
$global-xlarge-font-size:1.25rem;
$base-h2-font-size:1.25rem;
$global-large-font-size:1.25rem;
$base-h3-font-size:1.25rem;
$base-h4-font-size:1.25rem;
$global-medium-font-size:1.25rem;
$global-medium-font-size:1.25rem;

// $button-default-background:#fff;
$global-muted-background:#dedede;
$global-danger-background-hover:#c5c5c5;
$global-primary-background:#d92323;
$global-success-background-hover:#ad1c1c;
$global-success-background:#32db64;
$global-success-background-hover:#21b94e;
$global-warning-background:#faa05a;
$global-warning-background-hover:#f98429;
$global-danger-background:#d92323;
$global-danger-background-hover:#ad1c1c;

$global-emphasis-color:#222;
$global-color:#222;
$base-em-color:#222;
$global-muted-color:#aaa;
$global-secondary-background:#222;
$global-link-color:#d92323;
$global-link-hover-color:#bc1e1e;
$global-border:#dedede;
$base-selection-background:#bc1e1e;
$form-select-option-color:#222;
$grid-divider-border:#aaa;
$navbar-toggle-hover-color:$global-muted-background;

$table-header-cell-font-weight:$base-heading-font-weight;
$description-list-term-font-weight:$base-body-font-weight;
$table-cell-padding-vertical:$global-small-gutter;
$table-cell-padding-horizontal:$global-gutter;

$notification-message-primary-color:#fff;
$notification-message-success-color:#fff;
$notification-message-warning-color:#fff;
$notification-message-danger-color:#fff;
$notification-position:16px;

$height-small-height:100px;
$height-medium-height:200px;
$height-large-height:400px;
$modal-dialog-width:650px;

$navbar-nav-item-height:60px;
$navbar-nav-item-padding-horizontal:10px;

// 2. Import default variables and available mixins.
@import "uikit/variables-theme.scss";
@import "uikit/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-notification-message-primary{
	background-color:$global-primary-background;
}
@mixin hook-notification-message-success{
	background-color:$global-success-background;
}
@mixin hook-notification-message-warning{
	background-color:$global-warning-background;
}
@mixin hook-notification-message-danger{
	background-color:$global-danger-background;
}
@mixin hook-notification-close{
	color:#fff;
}
@mixin hook-form-label{
	-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}
@mixin hook-base-link{
	-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}
@mixin hook-base-heading{
	margin:0;
}
@mixin hook-nav-parent-icon{
	-webkit-transform:rotate(-90deg);
	-moz-transform:rotate(-90deg);
	-o-transform:rotate(-90deg);
	transform:rotate(-90deg);
}
@mixin hook-form-disabled{
	opacity:1;
	color:#222;
}
@mixin hook-base-misc{
	* + p,
	* + ul,
	* + ol,
	* + dl,
	* + pre,
	* + address,
	* + fieldset,
	* + figure{
		margin-top:0;
	}
}
@mixin hook-timer-number{
	font-family:$base-heading-font-family;
}

// 4. Import UIkit.
@import "uikit/uikit-theme.scss";

@mixin transition($property:all, $duration:0.25s, $timing-function:ease-in, $delay:0.25s){
	-webkit-transition:$property $duration $timing-function $delay;
	-moz-transition:$property $duration $timing-function $delay;
	-ms-transition:$property $duration $timing-function $delay;
	-o-transition:$property $duration $timing-function $delay;
	transition:$property $duration $timing-function $delay;
}
@mixin background-image{
	background-repeat:no-repeat;
	background-position:center center;
	background-attachment:scroll;
}
@mixin background-cover{
	-webkit-background-size:cover;
	-moz-background-size:cover;
	-o-background-size:cover;
	background-size:cover;
}
@mixin background-opaque($color:#fff, $opacity:0.9){
	background-color:rgba($color, $opacity);
}
@-webkit-keyframes autofill {
	to {
		color:$global-color;
		background-color:#fff;
		font-size:16px;
	}
}
.uk-flex{
	display:flex !important;
}
.uk-text-bold{
	font-weight: 600;
}
.uk-modal{
	transition:none;
}
.uk-open > .uk-modal-dialog,
.uk-modal-dialog{
	transform:none;
	transition:none;
}
video{
	width:100%;
	object-fit:cover;
}
input:-webkit-autofill {
	-webkit-animation-name:autofill;
	-webkit-animation-fill-mode:both;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	margin:0;
}
label{
	cursor:pointer;
}
.uk-border-top-primary{
	border-top:1px solid $global-primary-background;
}
.uk-border-top-secondary{
	border-top:1px solid $global-secondary-background;
}
.uk-border-top-muted{
	border-top:1px solid $global-muted-color;
}
.uk-border-bottom-primary{
	border-bottom:1px solid $global-primary-background;
}
.uk-border-bottom-secondary{
	border-bottom:1px solid $global-secondary-background;
}
.uk-border-bottom-muted{
	border-bottom:1px solid $global-muted-color;
}
.uk-navbar-container{
	position:relative;
	z-index:999;
	flex-wrap:wrap;
}
.uk-navbar-container:not(.uk-navbar-transparent){
	background-color:$global-secondary-background;
}
.uk-nav-default{
	margin-top:-$global-small-gutter;
}
.uk-sticky{
	z-index:999;
}
.search > input{
	color:#fff;
	&:focus{
		color:#fff;
	}
}
.body--dashboard-user-login,
.body--dashboard-user-signup{
	@include background-image;
	@include background-cover;
	background-image:url('/assets/img/background-with-overlay.jpg');
}
.body--dashboard-user-login .module .module-heading,
.body--dashboard-user-signup .module .module-heading{
	background-color:transparent;
}
.header *:last-child, .app *:last-child, .footer *:last-child, .uk-modal-body *:last-child{
	margin-bottom:0 !important;
}
.thumb{
	width:5rem;
	max-width:5rem;
}
.preview{
	background-color:$global-muted-background;
	width:6.25rem;
	height:6.25rem;
	margin:0 auto;
}
.logo-container{
	max-width:100%;
	margin:0 auto;
}
.logo{
	color:#fff;
	font-size:1rem;
	text-align:center;
	&:hover{
		color:#fff;
		text-decoration:none;
	}
}
.app-container{
	z-index:1;
}
.navigation-container{
	z-index:1;
}
.module-heading-container{
	z-index:0;
}
.trigger{
	cursor:pointer;
}
.module .module-heading{
	background-color:#fff;
}
.game-header-photo-container:before{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	content:'';
	width:100%;
	height:100%;
	background-image:-o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.75) 100%);
	background-image:-ms-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.75) 100%);
	background-image:-moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.75) 100%);
	background-image:-webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.75) 100%);
	background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.75) 100%);
}
/*
.game-timer-container,
.challenge-timer-container{
	@include background-opaque($global-muted-background, 0.8);
}
*/
.app .timer{
	font-family:$base-heading-font-family;
}
.app .timer a,
.app .timer a:hover{
	@include background-opaque($text-danger-color, 0.85);
	color:#fff;
}
.app .timer a:hover{
	@include background-opaque($text-danger-color, 1);
}
.app .timer a > .uk-icon{
	margin-top:-1px;
}
.challenges-heading > span{
	color:transparent;
	-webkit-text-fill-color:transparent;
	text-fill-color:transparent;
	-webkit-background-clip:text;
	-moz-background-clip:text;
	-ms-background-clip:text;
	background-clip:text;
}
.challenge-icon:hover{
	background-color:$global-danger-background-hover;
}
.challenge-icon-success,
.challenge-icon-failure{
	color:#fff;
}
.challenge-icon-success,
.challenge-icon-success:hover{
	background-color:$global-success-background;
}
.challenge-icon-success:hover{
	background-color:$global-success-background-hover;
}
.challenge-icon-failure,
.challenge-icon-failure:hover{
	color:#fff;
	background-color:$global-danger-background;
}
.challenge-icon-failure:hover{
	background-color:$global-danger-background-hover;
}
.locations{
	margin-top:-$global-small-gutter;
}
.footer{
	padding-bottom:$global-large-gutter + 20;
}
.password-unmask{
	position:absolute;
	top:0;
	right:0;
	z-index:1;
	padding:0.5rem;
    cursor:pointer;
}