//
// Component: Utility
//
// ========================================================================


// Variables
// ========================================================================


// Panel
// ========================================================================

// @mixin hook-panel-scrollable(){}


// Box-shadow bottom
// ========================================================================

// @mixin hook-box-shadow-bottom(){}


// Drop cap
// ========================================================================




// Logo
// ========================================================================

// @mixin hook-logo(){}

// @mixin hook-logo-hover(){}


// Miscellaneous
// ========================================================================

// @mixin hook-utility-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-dropcap(){}

// @mixin hook-inverse-logo(){}
// @mixin hook-inverse-logo-hover(){}