//
// Component: Nav
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$nav-default-font-size:                          $global-small-font-size !default;


// Sublists
// ========================================================================

// @mixin hook-nav-sub(){}


// Parent icon modifier
// ========================================================================

// @mixin hook-nav-parent-icon(){}


// Header
// ========================================================================

// @mixin hook-nav-header(){}


// Divider
// ========================================================================

// @mixin hook-nav-divider(){}


// Default style modifier
// ========================================================================



// @mixin hook-nav-default-item(){}

// @mixin hook-nav-default-item-hover(){}

// @mixin hook-nav-default-item-active(){}

// @mixin hook-nav-default-header(){}

// @mixin hook-nav-default-divider(){}


// Primary style modifier
// ========================================================================

// @mixin hook-nav-primary(){}

// @mixin hook-nav-primary-item(){}

// @mixin hook-nav-primary-item-hover(){}

// @mixin hook-nav-primary-item-active(){}

// @mixin hook-nav-primary-header(){}

// @mixin hook-nav-primary-divider(){}


// Miscellaneous
// ========================================================================

// @mixin hook-nav-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-nav-parent-icon(){}

// @mixin hook-inverse-nav-default-item(){}
// @mixin hook-inverse-nav-default-item-hover(){}
// @mixin hook-inverse-nav-default-item-active(){}
// @mixin hook-inverse-nav-default-header(){}
// @mixin hook-inverse-nav-default-divider(){}

// @mixin hook-inverse-nav-primary-item(){}
// @mixin hook-inverse-nav-primary-item-hover(){}
// @mixin hook-inverse-nav-primary-item-active(){}
// @mixin hook-inverse-nav-primary-header(){}
// @mixin hook-inverse-nav-primary-divider(){}