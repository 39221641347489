//
// Component: Breadcrumb
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-breadcrumb(){}


// Items
// ========================================================================

// @mixin hook-breadcrumb-item(){}

// @mixin hook-breadcrumb-item-hover(){}

// @mixin hook-breadcrumb-item-disabled(){}

// @mixin hook-breadcrumb-item-active(){}

// @mixin hook-breadcrumb-divider(){}


// Miscellaneous
// ========================================================================

// @mixin hook-breadcrumb-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-breadcrumb-item(){}
// @mixin hook-inverse-breadcrumb-item-hover(){}
// @mixin hook-inverse-breadcrumb-item-disabled(){}
// @mixin hook-inverse-breadcrumb-item-active(){}

// @mixin hook-inverse-breadcrumb-divider(){}