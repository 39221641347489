//
// Component: Article
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$article-meta-link-color:                        $article-meta-color !default;
$article-meta-link-hover-color:                  $global-color !default;


// Component
// ========================================================================

// @mixin hook-article(){}


// Adjacent sibling
// ========================================================================

// @mixin hook-article-adjacent(){}


// Title
// ========================================================================

// @mixin hook-article-title(){}


// Meta
// ========================================================================




// Miscellaneous
// ========================================================================

// @mixin hook-article-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-article-meta(){}