//
// Component: Section
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-section(){}


// Style modifiers
// ========================================================================

// @mixin hook-section-default(){}

// @mixin hook-section-muted(){}

// @mixin hook-section-primary(){}

// @mixin hook-section-secondary(){}


// Miscellaneous
// ========================================================================

// @mixin hook-section-misc(){}
