//
// Component: Progress
//
// ========================================================================


// Variables
// ========================================================================

$progress-border-radius:                         500px !default;


// Component
// ========================================================================



// @mixin hook-progress-bar(){}


// Miscellaneous
// ========================================================================

// @mixin hook-progress-misc(){}
