//
// Component: Text
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$text-meta-link-color:                           $text-meta-color !default;
$text-meta-link-hover-color:                     $global-color !default;


// Style modifiers
// ========================================================================

// @mixin hook-text-lead(){}




// Size modifiers
// ========================================================================

// @mixin hook-text-small(){}

// @mixin hook-text-large(){}


// Background modifier
// ========================================================================

// @mixin hook-text-background(){}


// Miscellaneous
// ========================================================================

// @mixin hook-text-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-text-lead(){}
// @mixin hook-inverse-text-meta(){}
