//
// Component: List
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$list-striped-border-width:                      $global-border-width !default;
$list-striped-border:                            $global-border !default;


// Style modifiers
// ========================================================================

// @mixin hook-list-divider(){}



// @mixin hook-list-bullet(){}


// Miscellaneous
// ========================================================================

// @mixin hook-list-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-list-divider(){}

// @mixin hook-inverse-list-bullet(){}