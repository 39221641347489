//
// Component: Form Range
//
// ========================================================================


// Variables
// ========================================================================

$form-range-thumb-background:                    $global-background !default;

//
// New
//

$form-range-thumb-border-width:                  $global-border-width !default;
$form-range-thumb-border:                        darken($global-border, 10%) !default;

$form-range-track-border-radius:                 500px !default;


// Component
// ========================================================================

// @mixin hook-form-range(){}


// Thumb
// ========================================================================




// Track
// ========================================================================



// @mixin hook-form-range-track-focus(){}


// Miscellaneous
// ========================================================================

// @mixin hook-form-range-misc(){}